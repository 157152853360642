import Vue from 'vue'
import Swal from 'sweetalert2'

Vue.prototype.$swal = Swal

export const swalUpdate = () => {
  return Swal.fire({
    title: '정말 변경하시겠습니까?',
    text: '변경 후 되돌릴 수 없습니다.',
    icon: 'warning',
    confirmButtonText: '변경',
    cancelButtonText: '취소',
    showCancelButton: true
  })
}

export const swalDelete = () => {
  return Swal.fire({
    title: '정말 삭제하시겠습니까?',
    text: '삭제 후 되돌릴 수 없습니다.',
    icon: 'warning',
    confirmButtonText: '삭제',
    cancelButtonText: '취소',
    showCancelButton: true
  })
}

export default Swal
