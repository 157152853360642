import firebase, { db } from '@/plugins/firebase'

export interface HeaderItem {
  value: string;
  text: string;
  selected: boolean;
}

export interface UserSettings {
  readonly headers?: HeaderItem[];
  readonly sortBy?: string;
  readonly sortDesc?: boolean;
  readonly selectedModel?: string;
  readonly dark?: boolean;
}
export class User {
  constructor (
    public id: string = '',
    readonly email: string,
    readonly providerData: string[],
    readonly displayName: string,
    readonly photoURL: string,
    readonly fcmToken: string,
    readonly actions: string[],
    readonly createdAt: Date | undefined,
    readonly updatedAt: Date | undefined,
    readonly settings: UserSettings
  ) { }
}

export const SettingsHeaders = [
  { value: 'info.modelNo', text: '모델', selected: true },
  { value: 'modemNo', text: '모뎀번호', selected: true },
  { value: 'info.version', text: '펌웨어버전', selected: true },
  { value: 'firmware', text: '설정펌웨어버전', selected: true },
  { value: 'receivedAt', text: '수신일', selected: true },
  { value: 'updatedAt', text: '수정일', selected: true },
  { value: 'update', text: '업데이트', selected: true },
  { value: 'view', text: '감시', selected: true },
  { value: 'log', text: '로그기록', selected: false },
  { value: 'group', text: '그룹', selected: true },
  { value: 'remark', text: '비고', selected: true },
  { value: 'createdAt', text: '생성일', selected: false },
  { value: 'info.time', text: '장치시간', selected: false },
  { value: 'info.driverName', text: '운전자명', selected: false, sortable: false },
  { value: 'info.driverCode', text: '운전자코드', selected: false, sortable: false },
  { value: 'info.carType', text: '차량유형', selected: false, sortable: false },
  { value: 'info.plateNo', text: '차량번호', selected: false },
  { value: 'info.vin', text: '차대번호', selected: false },
  { value: 'info.companyName', text: '회사명', selected: false },
  { value: 'info.companyNo', text: '사업자등록번호', selected: false },
  { value: 'info.approvalNo', text: '형식승인번호', selected: false, sortable: false },
  { value: 'info.serialNo', text: '제조일련번호', selected: false },
  { value: 'info.kFactor', text: 'K Factor', selected: false, sortable: false },
  { value: 'info.rpmFactor', text: 'RPM Factor', selected: false, sortable: false },
  { value: 'info.latLng.lat', text: '위도', selected: false, sortable: false },
  { value: 'info.latLng.lng', text: '경도', selected: false, sortable: false },
  { value: 'info.azimuth', text: '방위각', selected: false, sortable: false },
  { value: 'deviceType', text: '장치유형', selected: false },
  { value: 'logCount', text: '로그개수', selected: false },
  { value: 'id', text: '자세히', selected: true, sortable: false }
]

export const converter: firebase.firestore.FirestoreDataConverter<User> = {
  toFirestore: (model: Partial<User>, setOptions?: firebase.firestore.SetOptions) => {
    if (setOptions?.merge) {
      return Object.assign(model, { updatedAt: firebase.firestore.FieldValue.serverTimestamp() })
    }
    return {
      email: model.email,
      providerData: model.providerData,
      displayName: model.displayName,
      photoURL: model.photoURL,
      fcmToken: model.fcmToken || '',
      actions: model.actions,
      createdAt: model.createdAt,
      updatedAt: model.updatedAt,
      settings: model.settings
    }
  },
  fromFirestore: (snapshot: firebase.firestore.QueryDocumentSnapshot) => {
    const data = snapshot.data()
    let photoURL = data.photoURL
    if (photoURL.includes('gravatar')) photoURL += '?d=retro'
    const settings = data.settings
    if (settings.headers.length !== SettingsHeaders.length) settings.headers = SettingsHeaders
    return new User(
      snapshot.id,
      data.email,
      data.providerData,
      data.displayName,
      photoURL,
      data.fcmToken,
      data.actions,
      data.createdAt instanceof firebase.firestore.Timestamp ? data.createdAt.toDate() : undefined,
      data.updatedAt instanceof firebase.firestore.Timestamp ? data.updatedAt.toDate() : undefined,
      data.settings
    )
  }
}

export default db.collection('users').withConverter(converter)
