import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/database'
import 'firebase/firebase-messaging'
import firebaseConfig from '../../firebaseConfig'

firebase.initializeApp(firebaseConfig)
firebase.auth().useDeviceLanguage()

export const auth = firebase.auth()
export const db = firebase.firestore()
export const rdb = firebase.database()
// export const messaging = firebase.messaging()

if (process.env.VUE_APP_FIREBASE === 'localhost' && process.env.NODE_ENV === 'development') {
  auth.useEmulator('http://localhost:9099/')
  db.useEmulator('localhost', 5002)
  rdb.useEmulator('localhost', 9000)
  // firebase.functions().useEmulator('http://localhost', 5001)
}

Vue.prototype.$firebase = firebase

export default firebase
