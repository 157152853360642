export default {
  apiKey: 'AIzaSyAHgRo6XntK-Q4hquW8cdjtEvbRVPnVBSE',
  authDomain: 'loop-ota.firebaseapp.com',
  databaseURL: 'https://loop-ota.firebaseio.com',
  projectId: 'loop-ota',
  storageBucket: 'loop-ota.appspot.com',
  messagingSenderId: '788134937463',
  appId: '1:788134937463:web:3ad164dd0e9b89fbfe9772',
  measurementId: 'G-0J4P106NMJ'
}
