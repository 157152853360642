







































































import { Component, Vue } from 'vue-property-decorator'
import MessageStore from '@/store/message'
import DisplayTime from '@/components/common/display-time.vue'
@Component({ components: { DisplayTime } })
export default class SiteMessage extends Vue {
  get items () { return MessageStore.items }

  clear () {
    MessageStore.clear()
  }
}
