import Vue from 'vue'

Vue.filter('phoneNumber', (value: string) => {
  const p = value.padStart(11, '0')
  const ps = [
    p.substr(0, 3),
    p.substr(3, 4),
    p.substr(7, 4)
  ]
  if (process.env.VUE_APP_TEST) ps[1] = 'xxxx'
  return ps.join('-')
})
