






































































import { Vue, Component, Ref } from 'vue-property-decorator'
import { auth } from '@/plugins/firebase'
import firebaseConfig from '../../../firebaseConfig'

@Component<AuthSign>({
  created () {
    this.signIn()
  }
})
export default class AuthSign extends Vue {
  valid = false
  email = localStorage.getItem('emailForSignIn') || ''
  loading = false
  emailForSignIn = localStorage.getItem('emailForSignIn') || ''
  title = process.env.VUE_APP_SITE_TITLE

  @Ref('form') form!: HTMLElement & { validate(): boolean; reset(): void }

  get user () {
    return this.$store.state.auth.user
  }

  get config () {
    return firebaseConfig
  }

  get finishiSignUpUrl () {
    // return process.env.NODE_ENV === 'development'
    //   ? 'http://localhost:8080'
    //   : process.env.VUE_APP_SITE_URL
    return location.href
  }

  async sendSignInLinkToEmail () {
    if (!this.form.validate()) throw Error('유효한 이메일을 작성하세요')
    try {
      this.loading = true

      const actionCodeSettings = {
        url: this.finishiSignUpUrl,
        handleCodeInApp: true
      }
      await auth
        .sendSignInLinkToEmail(this.email, actionCodeSettings)

      localStorage.setItem('emailForSignIn', this.email)
      this.emailForSignIn = this.email
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  async signInWithGoogle () {
    try {
      this.loading = true
      const provider = new this.$firebase.auth.GoogleAuthProvider()
      await auth.signInWithPopup(provider)
    } finally {
      this.loading = false
    }
  }

  async signInWithFacebook () {
    throw new Error('준비되지 않았습니다(만들지 고민중..)')
  }

  async cancel () {
    this.emailForSignIn = ''
    localStorage.removeItem('emailForSignIn')
  }

  async signIn () {
    if (!auth.isSignInWithEmailLink(location.href)) return
    try {
      this.loading = true
      await this.$firebase.auth().signInWithEmailLink(this.email, location.href)
      localStorage.removeItem('emailForSignIn')
    } catch (err) {
      console.error(err.message)
    } finally {
      this.loading = false
    }
  }
}
