import Vue from 'vue'
import App from './App.vue'
import '@/plugins/firebase'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/store/auth'
import vuetify from './plugins/vuetify'
import '@/plugins/axios'
import '@/plugins/draggable'
import '@/plugins/moment'
import '@/plugins/toast'
import '@/plugins/alert'
import '@/plugins/map'
import AuthHandler from '@/handler/auth'
import '@/handler/error'
import '@/filters'

Vue.config.productionTip = false

const authHandler = new AuthHandler()
authHandler.run()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
