










import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import LayoutLoading from '@/components/layout/loading/index.vue'
import LayoutNormal from '@/components/layout/normal/index.vue'
import LayoutAuth from '@/components/layout/auth/index.vue'
import auth from '@/store/auth'

@Component({
  components: { LayoutLoading, LayoutNormal, LayoutAuth }
})
export default class App extends Vue {
  get auth () {
    return auth
  }
}
