
















import { Vue, Component } from 'vue-property-decorator'
import CenterLoading from '@/components/etc/center-loading.vue'

@Component({ components: { CenterLoading } })
export default class AuthEmailConfirm extends Vue {
  email = ''
  loading = false

  get user () {
    return this.$store.state.auth.user
  }

  cancel () {
    this.$emit('change', '')
  }

  async created () {
    if (this.$firebase.auth().isSignInWithEmailLink(location.href)) {
      let email: string = localStorage.getItem('emailForSignIn') || ''
      if (!email) {
        email =
          window.prompt('로그인 하려는 이메일을 다시 한번 입력하세요.') || ''
      }
      try {
        await this.$firebase.auth().signInWithEmailLink(email, location.href)
        localStorage.removeItem('emailForSignIn')
        this.$router.push('/')
      } catch (err) {
        console.error(err.message)
      }
    }
  }
}
