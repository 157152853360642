import firebase, { db } from '@/plugins/firebase'

export class Model {
  constructor (
    public id: string,
    public deviceCount: number,
    public firmwareCount: number,
    public updateCount: number,
    public viewCount: number,
    public groups?: string[] | firebase.firestore.FieldValue
  ) { }
}

const converter: firebase.firestore.FirestoreDataConverter<Model> = {
  toFirestore: (model: Model, setOptions?: firebase.firestore.SetOptions) => {
    if (setOptions?.merge) {
      return Object.assign(model)
    }
    return {
      deviceCount: model.deviceCount || 0,
      firmwareCount: model.firmwareCount || 0,
      updateCount: model.updateCount || 0,
      viewCount: model.viewCount || 0,
      groups: model.groups || []
    }
  },
  fromFirestore: (snapshot: firebase.firestore.QueryDocumentSnapshot) => {
    const data = snapshot.data()
    return new Model(
      snapshot.id,
      data.deviceCount,
      data.firmwareCount,
      data.updateCount,
      data.viewCount,
      data.groups
    )
  }
}

export default db.collection('models').withConverter(converter)
