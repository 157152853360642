import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/device',
    name: 'device',
    component: () => import('@/views/device/index.vue')
  },
  {
    path: '/view',
    name: 'view',
    component: () => import('@/views/view/index.vue')
  },
  {
    path: '/model',
    name: 'model',
    component: () => import('@/views/model/index.vue')
  },
  {
    path: '/device/:id',
    name: 'device-item',
    component: () => import('@/views/device/item.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user/index.vue')
  },
  {
    path: '/firmware',
    name: 'firmware',
    component: () => import('@/views/firmware/index.vue')
  },
  // {
  //   path: '/firmware/:id',
  //   name: 'firmware-id',
  //   component: () => import('../views/firmware/index.vue')
  // },
  {
    path: '/system',
    component: () => import('@/views/system/index.vue')
  },
  {
    path: '/log',
    component: () => import('@/views/log/index.vue')
  },
  {
    path: '/version',
    component: () => import('@/views/version/index.vue')
  },
  {
    path: '/info',
    component: () => import('@/views/info/index.vue')
  },
  {
    path: '/help',
    component: () => import('@/views/help/index.vue')
  },
  {
    path: '*',
    name: 'e404',
    component: () => import('@/views/e404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
