














































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import AuthSign from '@/components/auth/sign.vue'
import AuthEmailConfirm from '@/components/auth/email-confirm.vue'
import AuthNameInput from '@/components/auth/name-input.vue'
import AuthState from '@/components/auth/state.vue'
// import CenterLoading from '@/components/etc/center-loading.vue'
import HelpSign from '@/components/help/sign.vue'
import auth from '@/store/auth'
import { version } from '../../../../package.json'

@Component({
  components: {
    AuthSign, AuthEmailConfirm, AuthNameInput, AuthState, HelpSign
  }
})
export default class LayoutAuth extends Vue {
  emailForSignIn = localStorage.getItem('emailForSignIn') || ''
  version = version
  dialog = false

  get xs () { return this.$vuetify.breakpoint.xs }

  get fireUser () {
    return auth.fireUser
  }

  get user () {
    return auth.user
  }

  get siteConfig () {
    return {
      title: process.env.VUE_APP_SITE_TITLE,
      description: process.env.VUE_APP_SITE_DESCRIPTION
    }
  }

  change (email: string) {
    if (!email) {
      localStorage.removeItem('emailForSignIn')
      this.emailForSignIn = ''
      return
    }
    localStorage.setItem('emailForSignIn', email)
    this.emailForSignIn = email
  }

  signOut () {
    auth.signOut()
  }
}

