









import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import CenterLoading from '@/components/etc/center-loading.vue'

@Component({
  components: {
    CenterLoading
  }
})
export default class LayoutLoading extends Vue {
}

