

















import { Component, Vue } from 'vue-property-decorator'
import AuthStore from '@/store/auth'

  @Component
export default class SiteBanner extends Vue {
  get xs () {
    return this.$vuetify.breakpoint.xs
  }

  get version () {
    if (!AuthStore.system) return ''
    return AuthStore.system.version
  }

  get isSameVersion () {
    // return false
    return AuthStore.isSameVersion
  }

  get isVersionPage () {
    return this.$route.path === '/version'
  }
}
