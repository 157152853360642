import {
  getModule,
  Module,
  VuexModule,
  Mutation
} from 'vuex-module-decorators'
import store from './index'

interface Message {
  createdAt: Date;
  title: string;
  body: string;
  link: string;
}

@Module({
  namespaced: true,
  store,
  name: 'message',
  dynamic: true
})

class MessageStore extends VuexModule {
  items: Message[] = []

  @Mutation
  addItem (item: Message) {
    this.items.unshift(item)
  }

  @Mutation
  clear () {
    this.items = []
  }
}
export default getModule(MessageStore)
