

































































import { Component, Vue } from 'vue-property-decorator'
import AuthStore from '@/store/auth'
import UserItemContent from '@/components/user/item-content.vue'

@Component({ components: { UserItemContent } })
export default class SiteAccount extends Vue {
  get user () {
    return AuthStore.user
  }

  signOut () {
    AuthStore.signOut()
  }
}
