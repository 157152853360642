











import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import SiteAppBar from '@/components/site/app-bar.vue'
import SiteBanner from '@/components/site/banner.vue'

@Component<LayoutNormal>({
  components: { SiteAppBar, SiteBanner }
})
export default class LayoutNormal extends Vue {
  drawer = false

  get xs () {
    return this.$vuetify.breakpoint.xs
  }
}

