import {
  getModule,
  Module,
  VuexModule,
  Mutation
} from 'vuex-module-decorators'
import store from './index'
import firebase from '@/plugins/firebase'
import { Model } from '@/models/models'

const getTotal = (items: Model[]) => {
  const total = { id: '', deviceCount: 0, updateCount: 0, firmwareCount: 0, viewCount: 0 }
  items.forEach(item => {
    if (typeof item.deviceCount === 'number') total.deviceCount += item.deviceCount
    if (typeof item.updateCount === 'number') total.updateCount += item.updateCount
    if (typeof item.firmwareCount === 'number') total.firmwareCount += item.firmwareCount
    if (typeof item.viewCount === 'number') total.viewCount += item.viewCount
  })
  return total
}

const getFullItems = (items: Model[], total: Model) => {
  const fullItems = Object.assign(items)
  fullItems.unshift(total)
  return fullItems
}
@Module({
  namespaced: true,
  store,
  name: 'model',
  dynamic: true
})

class ModelStore extends VuexModule {
  snapshots: firebase.firestore.QueryDocumentSnapshot<Model>[] = []
  total: Model = { id: '', deviceCount: 0, updateCount: 0, firmwareCount: 0, viewCount: 0 }
  fullItems: Model[] = []
  @Mutation
  setSnapshot (sn: firebase.firestore.QuerySnapshot<Model> | null) {
    if (!sn) return
    this.snapshots = sn.docs
    const items = this.snapshots.map(snapshot => snapshot.data())
    this.total = getTotal(items)
    this.fullItems = getFullItems(items, this.total)
  }

  get items () {
    return this.snapshots.map(snapshot => snapshot.data())
  }
}
export default getModule(ModelStore)
