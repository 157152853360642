

















import { Vue, Component, Prop } from 'vue-property-decorator'
import auth from '@/store/auth'
@Component
export default class AuthState extends Vue {
  @Prop({ type: String, required: true }) message!: string

  get splitMessage () {
    return this.message.split('\\n').join('<br>')
  }

  created () {
    this.$firebase.auth().onAuthStateChanged(user => auth.setFirebaseUser(user))
  }

  signOut () { auth.signOut() }
}
