import Vue from 'vue'
import firebase from 'firebase'
import firmwareStore from '@/store/firmware'

Vue.filter('firmwareInfo', (fw: firebase.firestore.DocumentReference | null) => {
  if (!fw) return '지정 안함'
  const n = firmwareStore.items.find(item => item.id === fw.id)
  if (!n) return '펌웨어 없음'
  // return `${n.version.split('').join('.')}(${n.name})`
  return `${n.version.split('').join('.')}`
})
