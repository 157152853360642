import Vue from 'vue'
import axios from 'axios'
import firebaseConfig from '../../firebaseConfig'

const baseURL = process.env.VUE_APP_FIREBASE === 'localhost' && process.env.NODE_ENV === 'development'
  ? `http://localhost:5001/${firebaseConfig.projectId}/us-central1/api`
  : `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/api`

const api = axios.create({ baseURL })

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) config.headers.Authorization = 'Bearer ' + token
  return config
}, err => {
  console.error('request interceptor', err)
  throw Promise.reject(err)
})

api.interceptors.response.use((res) => {
  const rtk = res.headers['www-authenticate']
  if (rtk) {
    localStorage.setItem('token', rtk)
  }
  return Promise.resolve(res)
}, (err) => {
  console.error('response interceptor', err)
  throw Promise.reject(err)
})

Vue.prototype.$api = api

export default api
// https://github.com/loopsdteam/isg/blob/master/src/api/index.js
