










import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CenterLoading extends Vue {
  @Prop({ type: String, default: '로딩 중' }) message!: string
}
