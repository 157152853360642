





















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { User } from '@/models/users'
import userActions from '@/components/user/userActions'

  @Component
export default class UserItemContent extends Vue {
  @Prop({ type: Object, required: true }) user!: User
  actions = userActions

  findAction (value: string) {
    return this.actions.find(item => item.value === value)
  }
}
