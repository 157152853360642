import {
  getModule,
  Module,
  VuexModule,
  Mutation
} from 'vuex-module-decorators'
import store from './index'
import firebase from '@/plugins/firebase'
import { Firmware } from '@/models/firmwares'

@Module({
  namespaced: true,
  store,
  name: 'firmware',
  dynamic: true
})

class FirmwareStore extends VuexModule {
  snapshots: firebase.firestore.QueryDocumentSnapshot<Firmware>[] = []

  @Mutation
  setSnapshot (sn: firebase.firestore.QuerySnapshot<Firmware> | null) {
    if (!sn) return
    this.snapshots = sn.docs
  }

  get items () {
    return this.snapshots.map(snapshot => snapshot.data())
  }

  get selects () {
    return this.items.map(item => {
      return { value: item, text: `${item.name} ${item.version.split('').join('.')}` }
    })
  }
}
export default getModule(FirmwareStore)
