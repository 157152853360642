import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators'
import store from './index'
import firebase from '@/plugins/firebase'
import userCollection, { User, UserSettings } from '@/models/users'
import SystemJSON from '@/models/system'
import { version } from '../../package.json'

@Module({
  namespaced: true,
  store,
  name: 'auth',
  dynamic: true
})

class Auth extends VuexModule {
  loaded = false
  user: User | null = null
  token: string | null = localStorage.getItem('token')
  fireUser: firebase.User | null = null
  system: SystemJSON | null = null

  get isAuthenticated () {
    // return this.user !== null
    return this.user !== null && this.user.displayName && this.user.actions.includes('read')
  }

  get isAdmin () {
    // return this.user !== null
    return this.user !== null && this.user.displayName && this.user.actions.includes('admin')
  }

  get isDev () {
    // return this.user !== null
    return this.user !== null && this.user.displayName && this.user.actions.includes('dev')
  }

  get isSameVersion () {
    if (!this.system) return false
    return version === this.system.version
  }

  @Mutation
  setUser (user: User | null) {
    this.user = user
  }

  @Mutation
  setFirebaseUser (fireUser: firebase.User | null) {
    this.loaded = true
    this.fireUser = fireUser
  }

  @Mutation
  setSystem (system: SystemJSON | null) {
    this.system = system
  }

  @Action
  async updateDisplayName (displayName: string) {
    if (!this.fireUser) return
    const ref = userCollection.doc(this.fireUser.uid)
    await ref.set({ displayName }, { merge: true })
  }

  @Action
  async signOut () {
    if (!this.fireUser) return
    const ref = userCollection.doc(this.fireUser.uid)
    await ref.set({ fcmToken: '' }, { merge: true })
    await firebase.auth().signOut()
  }

  @Action
  async updateSettings (settings: UserSettings) {
    if (!this.fireUser) return
    const ref = userCollection.doc(this.fireUser.uid)
    await ref.set({ settings }, { merge: true })
  }
}
export default getModule(Auth)
