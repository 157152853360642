
























import { Vue, Component, Ref } from 'vue-property-decorator'
import auth from '@/store/auth'

@Component
export default class AuthNameInput extends Vue {
  displayName = ''
  loading = false

  @Ref('form') form!: HTMLElement & { validate(): boolean; reset(): void }

  private submit () {
    if (!this.form.validate()) throw Error('사용자 이름 입력 오류입니다')
    try {
      this.loading = true
      auth.updateDisplayName(this.displayName)
    } finally {
      this.loading = false
    }
  }

  signOut () {
    auth.signOut()
  }
}
