import firebase, { db } from '@/plugins/firebase'

export class Firmware {
  constructor (
    public id: string = '',
    readonly fileName: string,
    readonly fileSize: number,
    readonly modelNo: string,
    readonly name: string,
    readonly bufferSize: number,
    readonly version: string,
    readonly remark: string,
    readonly updatedAt?: Date,
    readonly createdAt?: Date
  ) {

  }
}

export const converter: firebase.firestore.FirestoreDataConverter<Firmware> = {
  toFirestore (model: Partial<Firmware>, setOptions?: firebase.firestore.SetOptions) {
    if (setOptions?.merge) {
      return Object.assign(model, { updatedAt: firebase.firestore.FieldValue.serverTimestamp() })
    }

    return {
      fileName: model.fileName,
      fileSize: model.fileSize,
      modelNo: model.modelNo,
      name: model.name,
      bufferSize: model.bufferSize,
      version: model.version,
      remark: model.remark,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    }
  },
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot) {
    const data = snapshot.data()

    return new Firmware(
      snapshot.id,
      data.fileName,
      data.fileSize,
      data.modelNo,
      data.name,
      data.bufferSize,
      data.version,
      data.remark,
      (data.updatedAt as firebase.firestore.Timestamp)?.toDate(),
      (data.createdAt as firebase.firestore.Timestamp)?.toDate()
    )
  }
}
export default db.collection('firmwares').withConverter(converter)
