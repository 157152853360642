// import firebase, { messaging } from '@/plugins/firebase'
import firebase, { rdb } from '@/plugins/firebase'
import authStore from '@/store/auth'
import MessageStore from '@/store/message'
import userCollection, { User } from '@/models/users'
import firmwareCollection from '@/models/firmwares'
import modelCollection from '@/models/models'
import firmware from '@/store/firmware'
import model from '@/store/model'
import vuetify from '@/plugins/vuetify'
// import { version } from '../../package.json'
// import router from '@/router'
interface SystemJSON {
  readonly version: string;
}
export default class AuthHandler {
  unsubscribe!: Function
  unsubscribeFirmwares!: Function
  unsubscribeModels!: Function
  unsubscribeFCM!: Function
  systemRef = rdb.ref('system')

  public run () {
    firebase.auth().onAuthStateChanged(async (fireUser) => {
      if (!fireUser) {
        if (typeof this.unsubscribe === 'function') this.unsubscribe()
        if (typeof this.unsubscribeFirmwares === 'function') this.unsubscribeFirmwares()
        if (typeof this.unsubscribeModels === 'function') this.unsubscribeModels()
        authStore.setUser(null)
        this.fcmOff()
        this.systemRef.off()
      } else {
        const ref = userCollection.doc(fireUser.uid)
        await this.fcmOn(ref).catch(console.error)
        ref.onSnapshot((doc) => {
          if (!doc.exists) return
          const data = doc.data()
          if (!data) return
          authStore.setUser(data)
          this.darkUpdate(data)

          fireUser.getIdToken()
            .then(token => localStorage.setItem('token', token))
          this.unsubscribeFirmwares = firmwareCollection.onSnapshot(sn => firmware.setSnapshot(sn))
          this.unsubscribeModels = modelCollection.onSnapshot(sn => model.setSnapshot(sn))
          this.systemRef.on('value', (sn) => {
            // if (!sn.exists()) return
            const system = sn.val()
            authStore.setSystem(system)
            // if (system && system.version === version) return
            // if (process.env.NODE_ENV !== 'development') return
            // if (!process.env.VUE_APP_VERION_CREATE) return
            // sn.ref.set({ version })
          })
        }, (e) => {
          console.error(e.message)
        })
      }
      authStore.setFirebaseUser(fireUser)
    })
  }

  private darkUpdate (user: User) {
    const themeDark = localStorage.getItem('themeDark') === 'true'
    const dark = user.settings.dark
    if (dark === undefined) return
    if (themeDark === dark) return
    vuetify.framework.theme.dark = dark
    localStorage.setItem('themeDark', dark ? 'true' : 'false')
  }

  private async fcmOn (ref: firebase.firestore.DocumentReference<User>) {
    // if (process.env.NODE_ENV === 'development') return

    try {
      if (!firebase.messaging.isSupported()) return
      const sn = await ref.get()
      if (!sn.exists) return
      const user = sn.data()
      const fcmToken = await firebase.messaging().getToken()
      if (fcmToken !== user?.fcmToken) await sn.ref.set({ fcmToken }, { merge: true })
      this.unsubscribeFCM = firebase.messaging().onMessage(message => {
        const notification = message.notification

        if (!notification) return
        MessageStore.addItem({
          createdAt: new Date(),
          title: notification.title,
          body: notification.body,
          link: notification.click_action
        })

        // const p = message.notification.click_action
        // if (!p) return
        // if (p !== router.currentRoute.path) router.push(p)
      }, e => console.error('fcm subscribe err: ' + e.message))
    } catch (e) {
      console.error(`fcmOn err: ${e.message}`)
    }
  }

  private fcmOff () {
    if (process.env.NODE_ENV === 'development') return
    if (typeof this.unsubscribeFCM === 'function') this.unsubscribeFCM()
  }
}
