









































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { version } from '../../../package.json'
import AuthStore from '@/store/auth'
import SiteAccount from '@/components/site/account.vue'
import SiteMessage from '@/components/site/message.vue'

@Component({ components: { SiteAccount, SiteMessage } })
export default class SiteAppBar extends Vue {
  drawer = false
  version = version

  items = [
    { icon: 'mdi-chart-donut', text: '요약', to: '/' },
    { icon: 'mdi-chip', text: '장치관리', to: '/device' },
    { icon: 'mdi-eye-outline', text: '감시목록', to: '/view' },
    { icon: 'mdi-shape-outline', text: '펌웨어관리', to: '/firmware' },
    { icon: 'mdi-history', text: '이력관리', to: '/log' },
    { icon: 'mdi-view-dashboard-outline', text: '모델관리', to: '/model' },
    { icon: 'mdi-account-multiple-outline', text: '사용자관리', to: '/user' },
    { icon: 'mdi-alpha-v-circle-outline', text: '업데이트', to: '/version' },
    { icon: 'mdi-help-circle-outline', text: '도움말', to: '/help' },
    { icon: 'mdi-server', text: '시스템관리', to: '/system' },
    { icon: 'mdi-information-outline', text: '시스템정보', to: '/info' }
  ]

  get auth () {
    return AuthStore
  }

  get siteConfig () {
    return {
      title: process.env.VUE_APP_SITE_TITLE,
      description: process.env.VUE_APP_SITE_DESCRIPTION
    }
  }

  toggleDark () {
    const dark = !this.auth.user?.settings.dark

    this.$vuetify.theme.dark = dark
    localStorage.setItem('themeDark', dark ? 'true' : 'false')
    this.auth.updateSettings({ dark })
  }
}

